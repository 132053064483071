<template>
  <div class="mobile-carousel title-btn" :style="'height:' + height">
    <div class="swiper-container" :class="swiperName">
      <slot></slot>
      <div class="swiper-pagination" :class="paginationClassName"></div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper"
export default {
  props: {
    height: {
      type: String,
      default: '5rem'
    },
    paginationClassName: {
      type: String,
      default: 'pagination4'
    },
    swiperName: {
      type: String,
      default: 'swiper2'
    }
  },
  mounted() {
    // 获取根节点<html>的计算样式
    var style = window.getComputedStyle(document.documentElement);
    // 获取font-size属性的值
    var fontSize = parseFloat(style.getPropertyValue('font-size'));
    let swiperName = '.' + this.swiperName
    let paginationClassName = '.' + this.paginationClassName
    new Swiper (swiperName, { // eslint-disable-line no-unused-vars
        // slidesPerView : 'auto',
        // loop: true,
        // direction: 'horizontal',
        width: fontSize * 6.52,
        pagination: {
          el: paginationClassName,
        },
    
    })
  }
}

</script>
<style>
/* 整体高度 */
/* .mobile-carousel {
  height: 400px;
} */
.mobile-carousel {
  padding-left: 0.4rem;
}
.mobile-carousel .swiper-container {
  height: 100%;
  padding-left: 4px;
}
.mobile-carousel .swiper-wrapper {
  height: calc(100% - 1rem);
  padding-top: 0.4rem;
}
.mobile-carousel .swiper-slide {
  width: 6.1rem !important;
  margin-right: 0.4rem;
  border-radius: 0.6rem;
  box-shadow: 0px 0px 4px 0px rgba(161, 208, 107, 0.50);
  top: 0.3rem;
  position: relative;
}
.mobile-carousel .swiper-slide-active {
  transform: translateY(-0.6rem);
  transition: all 0.3s linear 0s;
}
.mobile-carousel .swiper-pagination-bullet-active {
  background: #9DC86A;
}

</style>